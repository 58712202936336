import React, {useState} from 'react';
import { Navbar, Nav, Button, Image } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import ModalLogin from "./ModalLogin";
import { useUser} from "../contexts/user";
import logo from "../assets/images/logo.png"
import {isLoggedIn} from "../utils/Utils";
import Cookies from 'js-cookie';

const Header = () => {
    const [isVisible] = useState(true);
    const { username, setUsername } = useUser();
    const [showLoginModal, setShowLoginModal] = useState(false);
    
    const logInOutClicked = () => {
        if (isLoggedIn(username)) {
            Cookies.remove("accessToken");
            return window.location.reload();
        }
        setShowLoginModal(true);
    }

    return (
        <>
            <ModalLogin
                show={showLoginModal}
                setShow={setShowLoginModal}
                setUsername={setUsername}
            />
            { isVisible && (
                <>
                   
                    <Navbar
                        expand="md" 
                        style={{
                            paddingLeft: "20px", 
                            backgroundColor: "#181b1f",
                        }}
                    >
                        <Navbar.Brand href="/">
                        <span 
                            className="yellow logo-text"
                        >
                            <Image src={logo}/>
                        </span>
                        </Navbar.Brand>

                        <Navbar.Toggle 
                            aria-controls="basic-navbar-nav"
                            style={{marginRight: "20px"}}
                        />

                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/about">About PROBE</Nav.Link>
                            </Nav>
                        
                            <div>
                                {username}
                            </div>

                            <Button
                                variant="light" 
                                style={{
                                    marginLeft:"20px",
                                    marginRight:"10px"
                                }}
                                onClick={()=>{
                                    logInOutClicked();
                                }}
                            >
                                <PersonCircle /> {
                                    isLoggedIn(username) ? "Log out" : "Log In"
                                }
                            </Button>
                            
                        </Navbar.Collapse>

                    </Navbar>
                </>
            )}
        </>
  );
};

export default Header;
