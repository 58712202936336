import React, {useState, useEffect, useCallback} from 'react';
import {Row, Col, Button, Container, Form, InputGroup, Image} from "react-bootstrap";
import { Grid } from '@mui/material';
import ModalEditData from "../components/ModalEditData";
import ModalAddSatellite from "../components/ModalAddSatellite";
import SatelliteCard from '../components/SatelliteCard';
import SearchModal from '../components/SearchModal';
import { LuInfo } from "react-icons/lu";
import ProbeLogoPNG from "../assets/images/ProbeLogoPNG.png";
import { IoIosSearch } from "react-icons/io";
import { useUser } from "../contexts/user";
import Loading from "../components/Loading";
import { isLoggedIn } from "../utils/Utils";
import { debouncedSearch, filterSatellites } from '../utils/SearchUtils';

const Home = () => {
    const { username, satellites } = useUser();
    const [loggedIn, setLoggedIn] = useState(false);
    const [filters, setFilters] = useState([]);
    const [filteredSats, setFilteredSats] = useState([]);
    const [showSatModal, setShowSatModal] = useState(false);
    const [hrrChecked, setHrrChecked] = useState(false);
    const [selectedSat, setSelectedSat] = useState(null);
    const [showAddSatModal, setShowAddSatModal] = useState(false);
    const [showQueryModal, setShowQueryModal] = useState(false);
    const handleOpenQueryModal = () => setShowQueryModal(true);
    const handleCloseQueryModal = () => setShowQueryModal(false);
    const [parameter, setParameter] = useState('CountryId');
    const [operator, setOperator] = useState('=');
    let [value, setValue] = useState('');
    const [visibleCount, setVisibleCount] = useState(9); // Number of satellites initially visible

    useEffect(() => {
        setLoggedIn(isLoggedIn(username));
        // Log the properties of the first satellite object
        const firstSatellite = Object.values(satellites)[9];
        if (firstSatellite) {
            console.log('Satellite properties:', Object.keys(firstSatellite));
            console.log('Sample satellite object:', firstSatellite);
        }
    }, [username, satellites]);

    useEffect(()=>{
        setSelectedSat(satellites[selectedSat?.SatNo]);
    }, [satellites, selectedSat]);
    
    useEffect(() => {
        if (hrrChecked) {
            const matches = Object.values(satellites).filter(s => s.HrrRank !== null);
            matches.sort((a, b) => a.SatNo - b.SatNo);
            setFilteredSats(matches);
        } else {
            const allSats = Object.values(satellites);
            allSats.sort((a, b) => a.SatNo - b.SatNo);
            setFilteredSats(allSats);
        }
    }, [satellites, hrrChecked]);

    const handleSearchChange = useCallback((event) => {
        const searchValue = event.target.value;
        if (searchValue === '') {
            const allSats = Object.values(satellites);
            allSats.sort((a, b) => a.SatNo - b.SatNo);
            setFilteredSats(allSats);
        } else {
            debouncedSearch(searchValue, satellites, setFilteredSats, hrrChecked);
        }
    }, [satellites, hrrChecked, setFilteredSats]);

    const addFilter = () => {
        const newFilter = { 
            parameter, 
            operator, 
            value: ['<', '>', '<=', '>='].includes(operator) ? parseFloat(value) : value 
        };
        const updatedFilters = [...filters, newFilter];
        setFilters(updatedFilters);
        setParameter('CountryId');
        setOperator('=');
        setValue('');

        // Apply the filter immediately
        const filteredResults = filterSatellites(satellites, updatedFilters, hrrChecked);
        filteredResults.sort((a, b) => a.SatNo - b.SatNo);
        setFilteredSats(filteredResults);
        setVisibleCount(9); // Reset visible count when filters are applied
    };

    const removeFilter = (index) => {
        const updatedFilters = filters.filter((_, i) => i !== index);
        setFilters(updatedFilters);

        // Apply the updated filters immediately
        const filteredResults = filterSatellites(satellites, updatedFilters, hrrChecked);
        filteredResults.sort((a, b) => a.SatNo - b.SatNo);
        setFilteredSats(filteredResults);
        setVisibleCount(9); // Reset visible count when filters are removed
    };

    // Lazy Load more satellites when scrolling near bottom
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 50) {
                setVisibleCount((prevCount) => prevCount + 9); // Load 9 more satellites
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <ModalEditData 
                show={showSatModal}
                setShow={setShowSatModal}
                showFooter={true}
                selectedSat={selectedSat}
            />
            <Container style={{ paddingTop: "25px" }}>

            <div className="hero-section" style={{ backgroundImage: 'url("/path-to-your-image.jpg")', backgroundSize: 'cover', color: '#fff', textAlign: 'center', padding: '30px 0' }}>
                <Container>
                    <Row>
                        <Col>
                            <Image width="400px" src={ProbeLogoPNG} fluid />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{paddingTop:"1rem"}}>
                            <p>The most comprehensive and user-friendly global resource for satellite data.</p>
                            <p>100% Open Source and Machine Readable content.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

                <Row>
                    <Col sm="10">
                        <h4>Satellites ({filteredSats.length})</h4>
                    </Col>
                </Row>
                <br/>

                {/* SEARCH */}
                <Row className="mb-1">
                    <Form.Group controlId="search">
                        <InputGroup>
                            <Button
                                tabIndex="99"
                                variant="outline-secondary"
                            >
                                <IoIosSearch />
                            </Button>
                            <Form.Control
                                required
                                type="text"
                                className="form-control-lg"
                                name="search"
                                placeholder="Search (NoradId, Name, Data etc)..."
                                onChange={handleSearchChange}
                            />
                        </InputGroup>
                    </Form.Group>
                    <p style={{ paddingTop:"1rem"}}>
                        <LuInfo/> This is publicly available information and was not provided through Intel channels.
                    </p>
                </Row>
                
                {/* Applied Filters */}
                {filters.length > 0 && (
                    <Row className="mb-2"> {/* Reduced margin-bottom */}
                        <Col sm="10">
                            <ul style={{ paddingLeft: '0', listStyleType: 'none', display: 'flex', flexWrap: 'wrap', gap: '5px' }}> {/* Reduced gap */}
                                {filters.map((filter, index) => (
                                    <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.75px' }}> {/* Reduced margin-bottom */}
                                        <span style={{ 
                                            marginRight: '10px', 
                                            backgroundColor: '#4D5656', 
                                            padding: '5px 10px', 
                                            borderRadius: '5px' 
                                        }}>
                                            {filter.parameter} {filter.operator} "{filter.value}"
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                )}

                {isLoggedIn(username) && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Button
                            type="checkbox"
                            className="mb-4"
                            variant={hrrChecked ? "success" : "light"}
                            onClick={() => setHrrChecked(!hrrChecked)}
                        >
                            HRR Only
                        </Button>
                        <Button
                            className="mb-4"
                            variant="light"
                            onClick={() => setShowAddSatModal(true)}
                        >
                            Add Satellite
                        </Button>
                        <Button variant="light" 
                        className="mb-4"
                        onClick={handleOpenQueryModal}>
                            Search Filters
                        </Button>
                    </div>
                )}

                <SearchModal 
                    show={showQueryModal}
                    handleClose={handleCloseQueryModal}
                    parameter={parameter}
                    setParameter={setParameter}
                    operator={operator}
                    setOperator={setOperator}
                    value={value}
                    setValue={setValue}
                    addFilter={addFilter}
                    filters={filters}
                    removeFilter={removeFilter}
                />
                
                {Object.keys(satellites).length === 0 && (<Loading/>)}
                <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
                    {filteredSats.slice(0, visibleCount).map((sat) => (
                        <Grid item key={sat?.Id ?? Math.random().toString()} xs={12} sm={12} md={6} lg={4}>
                            <SatelliteCard
                                setShowSatModal={setShowSatModal} 
                                sat={sat}
                                loggedIn={loggedIn}
                                setSelectedSat={setSelectedSat}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <ModalAddSatellite show={showAddSatModal} setShow={setShowAddSatModal} />
        </>
    );
};

export default Home;
