import React, {useEffect, useState} from "react";
import {Button, Modal, Image, Accordion, Row, Col, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import '../css/sat-modal.css';
import { timeDifference, getFlagLink } from "../utils/Utils";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useUser } from "../contexts/user";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import { FiAlertTriangle } from "react-icons/fi";
import ModalAddSchema from "./ModalAddSchema";
import { MsgTypes } from "../utils/Websockets";

let timeoutId;

const ignoreSchemas = [
    "last_updated_at",
    "last_updated_by",
    "nominated_at",
    "nominated_by",
]
function ModalEditData({
    show,
    setShow,
    showFooter,
    selectedSat,
}) {
    const [showAddSchemaModal, setShowAddSchemaModal] = useState(false);
    const { ws, username, newMsg } = useUser();
    const [filteredSchemas, setFilteredSchemas] = useState([]);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [saving, setSaving] = useState(false);
    const [showImage, setShowImage] = useState(false);

    const handleClose = () => setShow(false);

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this schema?")) {
            ws.DeleteSatellite({ Id: id });

            // Immediately update the local state
            setFilteredSchemas(prev => prev.filter(schema => schema.Id !== id));
            
            setUpdateSuccess(
                <span style={{color:"#ffc107"}}> 
                    <CiCircleCheck style={{fontSize:"1.5rem"}}/> Deleting...
                </span>
            );

            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setUpdateSuccess(<></>), 2000);
        }
    };

    const handleAddSchema = (newSchema) => {
        setFilteredSchemas(prevSchemas => {
            const updatedSchemas = [...prevSchemas, newSchema];
            return updatedSchemas.sort((a, b) => {
                const schemeA = a.Scheme?.toUpperCase() || '';
                const schemeB = b.Scheme?.toUpperCase() || '';
                return schemeA.localeCompare(schemeB);
            });
        });
    };
    
    useEffect(()=>{
        if(!newMsg)return;
        if (newMsg.id.split("-")[0] === MsgTypes.UpdatePsra){ // UpdatePsra-<updateRowId> for selecting new updates
            setSaving(false);
            setUpdateSuccess(newMsg.data.changedRows === 1 ?
                (<span style={{color:"#ffc107"}}> 
                    <CiCircleCheck style={{fontSize:"1.5rem"}}/> Saved
                </span>)
                :<span style={{color:"red"}}> 
                    <CiCircleRemove style={{fontSize:"1.5rem"}}/> Error updating, please try again.
                </span>
            );
            clearTimeout(timeoutId);
            timeoutId = setTimeout(()=>setUpdateSuccess(<></>),2000);
        }
    },[newMsg, setShow]);

    useEffect(() => {
        if (!newMsg) return;
        if (newMsg.id === MsgTypes.DeleteSatellite) {
            if (newMsg.data.affectedRows === 1) {
                setUpdateSuccess(
                    <span style={{color:"#ffc107"}}> 
                        <CiCircleCheck style={{fontSize:"1.5rem"}}/> Deleted
                    </span>
                );
                setFilteredSchemas(prev => prev.filter(schema => schema.Id !== newMsg.data.deletedId));
            } else {
                setUpdateSuccess(
                    <span style={{color:"red"}}> 
                        <CiCircleRemove style={{fontSize:"1.5rem"}}/> Error deleting, please try again.
                    </span>
                );
            }
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setUpdateSuccess(<></>), 2000);
        }
    }, [newMsg]);

    const updateSchema = (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const id = data.get("id");
        const source = data.get("source");
        const value = data.get("value");
        setSaving(true);
        setUpdateSuccess(<></>);
        ws.updatePsra(id,source,value,username);
    };

    useEffect(() => {
        if (!selectedSat?.Data) return;
        const filteredAndSortedData = selectedSat.Data
            .filter((x) => !ignoreSchemas.includes(x.Scheme))
            .sort((a, b) => {
                const schemeA = a.Scheme?.toUpperCase() || '';
                const schemeB = b.Scheme?.toUpperCase() || '';
                return schemeA.localeCompare(schemeB);
            });
    
        setFilteredSchemas(filteredAndSortedData);
    }, [selectedSat]);

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip">
          {text}
        </Tooltip>
    );

    const hideImage = ()=>{
        setShowImage(false);
    }

    return (
        selectedSat && <>
            <ModalAddSchema
                sat={selectedSat}
                show={showAddSchemaModal}
                setShow={setShowAddSchemaModal}
                onAddSchema={handleAddSchema}
            />

            <Modal
                className="sat-modal"
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        style={{
                            fontSize: "24px"
                        }}>
                        Editing <b>{selectedSat?.Name} - ({selectedSat?.SatNo})</b>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="text-center mb-4">
                        {showImage && (
                            <Image
                                style={{
                                    width:"50px",
                                    height: "50px",
                                    position: 'absolute',
                                    top: 10,
                                }}
                                alt={selectedSat?.CountryId}
                                src={getFlagLink(selectedSat)}
                                onError={hideImage}
                            />
                        )}
                        
                        <Carousel
                            showThumbs={false}
                        >
                            {(selectedSat?.getImages(selectedSat?.Data) &&
                                selectedSat?.getImages(selectedSat?.Data).map((url) => (
                                    <Image
                                        key={url}
                                        style={{
                                            height: "100%",
                                            width:"100%",
                                            maxWidth:"400px",
                                        }}
                                        src={url} rounded 
                                    />
                                ))
                            )}
                        </Carousel>
                    </div>
                    <Row className="mb-4">
                        <Col>
                            <Button 
                                variant="warning"
                                onClick={()=>setShowAddSchemaModal(!showAddSchemaModal)}
                            > + Attach Schema</Button>
                            </Col>
                    </Row>
                    <Row 
                        className= "bold"
                        style={{paddingLeft:"20px", paddingRight:"45px"}}
                    >
                        <Col xs={2}>Schema</Col>
                        <Col xs={5}>Value</Col>
                        <Col xs={2}>Verified by</Col>
                        <Col xs={3} style={{textAlign:"right"}}>Last Updated</Col>
                    </Row>

                    <Accordion 
                            variant="dark" 
                            defaultActiveKey="0">
                    {
                        filteredSchemas 
                        && filteredSchemas.map((data) => (
                            <Accordion.Item 
                                key={data.Id+data.Source+data.Value} 
                                eventKey={data.Id}
                            >
                                <Accordion.Header>
                                    <Row 
                                        style={{
                                            fontWeight: 700,
                                            width:"100%"
                                        }}
                                    >
                                        <Col xs={2}>
                                            {(!data.Source || !data.Value) && (
                                                <OverlayTrigger
                                                    placement="top"
                                                     overlay={renderTooltip("Missing data")}
                                                >
                                                    <span
                                                        style={{color:"red"}}>
                                                        <FiAlertTriangle /> 
                                                    </span> 
                                                </OverlayTrigger>
                                            )}
                                            &nbsp; {data.Scheme}
                                        </Col>
                                        <Col xs={5}>{data.Value}</Col>
                                        <Col xs={2}>{data.VerifiedBy}</Col>
                                        <Col xs={3} style={{textAlign:"right"}}>{timeDifference(data.UpdatedAt)}</Col>
                                    </Row>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gray-bg">
                                        <Form id={data.Id} onSubmit={updateSchema}>
                                            <Form.Group className="mb-3" controlId="formDepartment">
                                                <Form.Label>Source URL</Form.Label>
                                                <Form.Control
                                                    className="mb-2"
                                                    name="id"
                                                    type="hidden"
                                                    defaultValue={data.Id}
                                                    required
                                                />
                                                <Form.Control
                                                    className="mb-2"
                                                    name="source"
                                                    type="text"
                                                    defaultValue={data.Source ?? ""}
                                                    required
                                                />
                                                <Form.Label>Value</Form.Label>
                                                <Form.Control
                                                    className="mb-2"
                                                    name="value"
                                                    type="text"
                                                    defaultValue={data.Value ?? ""}
                                                    required
                                                />
                                                <Button
                                                    className="m-2"
                                                    type="submit"
                                                    variant="warning"
                                                    disabled={saving}
                                                >
                                                    Update
                                                </Button>
                                                <Button
                                                    className="m-2"
                                                    variant="danger"
                                                    onClick={() => handleDelete(data.Id)}
                                                >
                                                    Delete
                                                </Button>
                                                {updateSuccess}
                                            </Form.Group>
                                        </Form>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                    ))}
                     </Accordion>
                </Modal.Body>
                {showFooter ? (
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            className="decline-delete-button"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                ):(<></>)}
                
            </Modal>
        </>
    );
}

export default ModalEditData;
