import React from 'react';

class SpacefanaDashboard extends React.Component {
    render() {
        const {satNumber} = this.props;

        return (
            <iframe 
                src={`https://spacefana.saberastro.com/d/PO81TfmVk/elsethistory?orgId=1&var-satnos=${satNumber}&var-udlp=&var-showSourceColor=Yes&var-lines=Yes&var-accesstoken=&var-stdDev=3&var-showWindu=Yes&from=1715977837024&to=1716582637025&panelId=2`} 
                width="100%" 
                height="500px" 
                title="Spacefana Preview">
            </iframe>
        );
    }
}

export default SpacefanaDashboard;