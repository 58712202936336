import React, { useEffect, useState } from 'react';
import SatelliteCard from '../components/SatelliteCard';
import SpacefanaDashboard from '../components/SpacefanaDashboard';
import DisplaySatellites3D from "../components/DisplaySatellites3D";
import { useParams } from 'react-router-dom';
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { getScheme } from '../utils/Utils';
import { HiOutlineCube } from "react-icons/hi2";
import { CgDetailsMore } from "react-icons/cg";
import { GiLightningFrequency } from "react-icons/gi";
import { MdOutlineSatelliteAlt } from "react-icons/md";
import { LiaRocketSolid } from "react-icons/lia";
import { GiOrbit } from "react-icons/gi";
import Poi from '../components/Poi';
import ModalEditData from '../components/ModalEditData';
import { useUser} from "../contexts/user";
import { timeDifference } from '../utils/Utils';
import NotFound from '../components/NotFound';
import { Helmet } from 'react-helmet';
import { LuInfo } from "react-icons/lu";
import { isLoggedIn } from "../utils/Utils";
import { FiEdit } from 'react-icons/fi';
import { GiChart } from 'react-icons/gi';
import { VscGraphScatter } from "react-icons/vsc";

const Dossier = () => {
    const { username, satellites } = useUser();
    const { id } = useParams();
    const [notFound, setNotFound] = useState(false);
    const [sat, setSat] = useState(null);
    const [pois, setPois] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSpacefanaModal, setShowSpacefanaModal] = useState(false);
    const [associatedSatellites, setAssociatedSatellites] = useState([]);
    const [show3dViewModal, setShow3dViewModal] = useState(false);
    
    useEffect(() => {
        if (sat?.Data) {
            const assocSats = getScheme(sat.Data, ["assoc_sats"])[0].Value;
            if (assocSats !== "N/A") {
                setAssociatedSatellites(assocSats.split(',').map(s => s.trim()));
            } else {
                setAssociatedSatellites([]);
            }
        }
    }, [sat?.Data]);
    
    useEffect(()=>{ 
        if (satellites[id]) {
            setNotFound(false);
            return setSat({...satellites[id]});
        } else if (Object.keys(satellites).length > 0) {
            setNotFound(true);
        }
    },[id, satellites, setSat]);

    useEffect(()=>{
        if(sat?.Data){
            setPois(getScheme(sat.Data, ["poi"])[0].Value === "N/A" ? [] : getScheme(sat.Data, ["poi"]));
        }
    },[sat, sat?.Data, setPois]);
     
    const toggleEditModal = () => setShowEditModal(!showEditModal);
    const toggleSpacefanaModal = () => {
        setShowSpacefanaModal(!showSpacefanaModal);
    }
    
    const toggle3dViewModal = () => {
        setShow3dViewModal(!show3dViewModal);
    };

    const associatedSatID = associatedSatellites.map((ida) => <li>{ida}</li>);

    const associatedSatNames = associatedSatellites.map((ida) => <li>{satellites[parseInt(ida)]?.Name ? satellites[parseInt(ida)].Name : " "}</li>);

    

    return (
        <>
            {notFound && (
                <NotFound
                    id={id}
                >
                </NotFound>
            )}
            {sat?.Data && (

                <div style={{padding:"20px"}}>
                    <Helmet>
                        <meta name="satellite-name" content={sat ? `Satellite: ${sat.Name}` : "Satellite Details"} />
                        <meta property="description" content={sat ? sat.getDescription(sat.Data) : "No satellite data available"} />
                    </Helmet>
                    <Row>
                        <Col md={8} className="mb-3">

                            {/* Edit Data Button */}
                            {isLoggedIn(username) && (
                                <div className="mb-3" style={{ display: 'flex', gap: '20px' }}>
                                    <Button variant="outline-primary" onClick={toggleEditModal} style={{ borderColor: 'white', color: 'white' }}>
                                        <FiEdit /> Edit Data
                                    </Button>
                                    <Button onClick={toggleSpacefanaModal} variant="outline-primary" style={{ borderColor: 'white', color: 'white' }}>
                                        <GiChart /> Elset History
                                    </Button>
                                    <Button onClick={toggle3dViewModal} variant="outline-primary" style={{ borderColor: 'white', color: 'white' }}>
                                        <VscGraphScatter /> 3D Visual
                                    </Button>
                                </div>
                            )}
                            
                            {/* Edit Modal Data */}
                            <ModalEditData
                                show={showEditModal}
                                setShow={setShowEditModal}
                                selectedSat={sat}
                            />

                            {/* Summary */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title id="summary">
                                        Summary 
                                    </Card.Title>
                                    <span style={{fontStyle: "italic"}}>
                                        <LuInfo/> 
                                        This is publicly available information and was not provided through Intel channels.
                                    </span>
                                    <br/>
                                    {sat.getDescription(sat.Data)}
                                </Card.Body>
                            </Card> 

                            {/* Orbit */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title><GiOrbit/> Orbit</Card.Title>
                                    <div className="pb-3">
                                        {getScheme(sat?.Data, ["launch_summary"])[0].Value}
                                    </div>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Last Seen (18th SPCS)</Col>
                                                <Col>[{timeDifference(sat?.Epoch)}] {sat?.Epoch}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Inclination</Col>
                                                <Col>{sat?.Inclination}</Col>
                                            </Row>
                                            
                                        </Col>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Longitude (°E)</Col>
                                                <Col>{sat?.Longitude}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Longitude Drift (°E/day)</Col>
                                                <Col>{sat?.LonDriftDegreesPerDay}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {(associatedSatellites.length < 1) && (
                                        <Row style={{marginTop: '2rem'}}><Col sm="4">*Satellite has no associated satellites.</Col></Row>
                                    )}
                                    {(associatedSatellites.length >= 1) && (
                                        <Row style={{marginTop: '2rem'}}>
                                            <Col sm="6">
                                                <Row>
                                                    <Col sm="4">Associated Sat. Name(s)</Col>
                                                    <Col><ul style={{padding:'0%'}}>{associatedSatNames}</ul></Col>
                                                </Row>
                                            </Col>
                                            <Col sm="6">
                                                <Row>
                                                    <Col sm="4">Associated Sat. NoradID(s)</Col>
                                                    <Col><ul style={{padding:'0%'}}>{associatedSatID}</ul></Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )}
                                </Card.Body>
                            </Card> 

                            {/* Launch Details*/}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title><LiaRocketSolid/> Launch Details</Card.Title>
                                    <div className="pb-3">
                                        {getScheme(sat?.Data,["launch_summary"])[0].Value}
                                    </div>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Launch Date</Col>
                                                <Col>{getScheme(sat?.Data, ["launch_date"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Launch Vehicle</Col>
                                                <Col>{getScheme(sat?.Data, ["launch_vehicle"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Launch Mass</Col>
                                                <Col>{getScheme(sat?.Data, ["launch_mass"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Launch Profile</Col>
                                                <Col>{getScheme(sat?.Data, ["launch_profile"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Interim Orbits</Col>
                                                <Col>{getScheme(sat?.Data, ["interim_orbits"])[0].Value}</Col>
                                            </Row>
                                        </Col>

                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Separation Waypoint</Col>
                                                <Col>{getScheme(sat?.Data, ["separation_waypoint"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Deployment Waypoint</Col>
                                                <Col>{getScheme(sat?.Data, ["deployment_waypoint"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Itu Slot Projected</Col>
                                                <Col>{getScheme(sat?.Data, ["itu_slot_projected"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Launch History For Similar</Col>
                                                <Col>{getScheme(sat?.Data, ["launch_history_for_similar"])[0].Value}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card> 

                            {/* Details */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title><CgDetailsMore/> Details</Card.Title>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Owner</Col>
                                                <Col sm="7">{getScheme(sat?.Data, ["owner"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Operator</Col>
                                                <Col>{getScheme(sat?.Data, ["operator"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Payload Manufacturer</Col>
                                                <Col>{getScheme(sat?.Data, ["payload_manufacturer"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Names</Col>
                                                <Col>{getScheme(sat?.Data, ["name"])[0].Value}</Col>
                                            </Row>
                                        </Col>

                                        <Col sm="6">
                                        <Row>
                                                <Col sm="4">Type</Col>
                                                <Col>{getScheme(sat?.Data, ["is_military"])[0].Value === "1"
                                                    ? "Military"
                                                    : getScheme(sat?.Data, ["is_military"])[0].Value === "0"
                                                        ? "Civilian"
                                                        : "N/A"}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">ITU Name</Col>
                                                <Col>{getScheme(sat?.Data, ["itu_name"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Primary Mission</Col>
                                                <Col>{getScheme(sat?.Data, ["primary_mission"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Secondary Mission</Col>
                                                <Col>{getScheme(sat?.Data, ["secondary_mission"])[0].Value}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* Characteristics */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title><HiOutlineCube/> Characteristics</Card.Title>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Payload</Col>
                                                <Col>{getScheme(sat?.Data, ["payload"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Bus</Col>
                                                <Col>{getScheme(sat?.Data, ["bus"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Size</Col>
                                                <Col>{getScheme(sat?.Data, ["size"])[0].Value}</Col>
                                            </Row>
                                            
                                        </Col>

                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Propulsion</Col>
                                                <Col>{getScheme(sat?.Data, ["propulsion"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">RCS/Body Dimension</Col>
                                                <Col>{getScheme(sat?.Data, ["rcs_body_dimension"])[0].Value}</Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* Frequencies/Comms */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title><GiLightningFrequency/> Frequencies & Comms</Card.Title>
                                    <div className="pb-3">
                                        {getScheme(sat?.Data,["comms_description"])[0].Value}
                                    </div>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Freq Bands</Col>
                                                <Col>{getScheme(sat?.Data, ["freq_band"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Freq Range</Col>
                                                <Col>{getScheme(sat?.Data, ["frequency"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">TT&C Uplink Freqs</Col>
                                                <Col>{getScheme(sat?.Data, ["ttc_uplink_freqs"])[0].Value}</Col>
                                            </Row>
                                        </Col>

                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">TT&C Downlink Freqs</Col>
                                                <Col>{getScheme(sat?.Data, ["ttc_downlink_freqs"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">TT&C Crosslink Freqs</Col>
                                                <Col>{getScheme(sat?.Data, ["ttc_crosslink_freqs"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Polarization</Col>
                                                <Col>{getScheme(sat?.Data, ["polarization"])[0].Value}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* Satellite Status*/}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title><MdOutlineSatelliteAlt/> Satellite Status</Card.Title>
                                    <div className="pb-3">
                                        {getScheme(sat?.Data,["status_description"])[0].Value}
                                    </div>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Est. Mission Capacity</Col>
                                                <Col>{getScheme(sat?.Data, ["estimated_mission_capacity"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Est. Remaining Fuel</Col>
                                                <Col>{getScheme(sat?.Data, ["est_remaining_fuel"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Est. Customers</Col>
                                                <Col>{getScheme(sat?.Data, ["est_customers_supported"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Est. Current Mass</Col>
                                                <Col>{getScheme(sat?.Data, ["est_current_mass"])[0].Value}</Col>
                                            </Row>
                                        </Col>

                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Deviation from Mission</Col>
                                                <Col>{getScheme(sat?.Data, ["deviation_from_mission"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Observed Mil Ties</Col>
                                                <Col>{getScheme(sat?.Data, ["observed_foreign_mil_ties"])[0].Value}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Possible Anomalies</Col>
                                                <Col>{getScheme(sat?.Data, ["possible_anomalies"])[0].Value}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* NEW MODAL FOR ELSET HISTORY */}
                            <Modal
                                show={showSpacefanaModal}
                                onHide={toggleSpacefanaModal}
                                size="lg"
                                aria-labelledby="spacefana-modal"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="spacefana-modal">
                                        {sat?.Name}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h3>Spacefana Dashboard</h3>
                                    {sat && (
                                        <SpacefanaDashboard
                                            satNumber={sat.SatNo}
                                            useIframe={true}
                                        />
                                    )}
                                </Modal.Body>
                            </Modal>

                            {/* Modal for 3D View */}
                            <Modal
                                show={show3dViewModal}
                                onHide={toggle3dViewModal}
                                size="xl"
                                aria-labelledby="3d-view-modal"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="3d-view-modal">
                                        {sat?.Name}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {sat && (
                                        <DisplaySatellites3D 
                                            satellites={[sat.SatNo, ...associatedSatellites]} 
                                            useIframe={true}
                                        />
                                    )}
                                </Modal.Body>
                            </Modal>
                        </Col>

                        {/* Right Column */}
                        <Col 
                            md={4} 
                            className="mb-4"
                        >
                            {sat 
                                ?
                                (
                                    <>
                                        <SatelliteCard 
                                            sat={sat} 
                                        />
                                        <Poi
                                            sat={sat}
                                            pois={pois} 
                                        />
                                    </>
                                ) 
                                :(
                                <></>)}
                        </Col>
                    </Row>
                </div>
            )}
        </>
      );
};

export default Dossier;
