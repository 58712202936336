import React from 'react';
import sword from "../assets/images/sword.png";
import {Image} from 'react-bootstrap';

const Loading = () => {
  return (
    <>
        <div 
            className="text-center mt-5 mb-5"
        >
            <Image
                src={sword}
                style={{width:"100px"}}
                className="rotating-image mb-4"
            />
            <p>
                Loading..
            </p>
        </div>
    </>
  )
};

export default Loading;
