import React from "react";
import {Modal} from "react-bootstrap";
import '../css/sat-modal.css';
const ModalIframe = ({
    show,
    setShow,
    url,
}) => {
    const handleClose = () => {
        setShow(false);
    };

    return (
        <>
            <Modal
                show={show}
                className="iframe-modal"
                onHide={handleClose}
                fullscreen={true}
            >
                <Modal.Header closeButton >
                    <Modal.Title/>
                </Modal.Header>
                
                <Modal.Body style={{padding:0, overflow:"hidden"}}>
                    <iframe src={url} width="100%" height="100%" title="Space Cockpit"></iframe>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ModalIframe;
