import React, {useState, useEffect} from 'react';
import { Button, Row } from 'react-bootstrap';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { timeDifference, isLoggedIn } from '../utils/Utils';
import { LiaMapMarkerAltSolid } from "react-icons/lia";
import { useUser } from "../contexts/user";
import ModalAddPoi from './ModalAddPoi';

const Poi = ({ sat, pois }) => {
    const { username} = useUser();
    const [show, setShow] = useState(false);
    const [sortedPois, setSortedPois] = useState([]);

    useEffect(()=>{
        if(Array.isArray(pois)){
            const sorted = pois.sort((a,b)=>new Date(b.Timestamp) - new Date(a.Timestamp));
            setSortedPois(sorted);
        }
    }, [pois]);

  return (
    isLoggedIn(username) ?
        <>
            <ModalAddPoi
                show={show}
                setShow={setShow}
                sat={sat}
            />
            <Timeline>
                {sortedPois.length > 0 && sortedPois.map((poi) => (
                    <TimelineItem
                        key={poi.Timestamp}>
                        <TimelineOppositeContent >
                            {timeDifference(poi.Timestamp)}
                            <br/>
                            ({poi.Timestamp}Z)
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent style={{fontWeight:"bold"}}>{poi.Value}</TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
            <Row className="text-center">
                <Button
                    style={{
                        maxWidth:"150px",
                        marginLeft:"auto",
                        marginRight: "auto"
                    }}
                    variant="warning"
                    onClick={()=>setShow(!show)}
                >
                    <LiaMapMarkerAltSolid /> Add POI
                </Button>
            </Row> 
        </>
    : 
        <div
            className="text-center pt-4"
            style={{color:"red"}}
        >
            Must be logged in to view the Point of Interest timeline.
        </div>
  );
};

export default Poi;
