import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const SearchModal = ({ 
    show, 
    handleClose, 
    parameter, 
    setParameter, 
    operator, 
    setOperator, 
    value, 
    setValue, 
    addFilter, 
    filters, 
    removeFilter 
}) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Apply Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Parameter</Form.Label>
                        <Form.Select
                            value={parameter}
                            onChange={(e) => setParameter(e.target.value)}
                        >
                            <option>CountryId</option>
                            <option>Epoch</option>
                            <option>HrrRank</option>
                            <option>Inclination</option>
                            <option>Launch Date</option>
                            <option>Longitude Drift Degress</option>
                            <option>Longitude</option>
                            <option>Name</option>
                            <option>ObjectType</option>
                            <option>Raan</option>
                            <option>Regime</option>
                            <option>RegimeInt</option>
                            <option>SatNo</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Operator</Form.Label>
                        <Form.Select
                            value={operator}
                            onChange={(e) => setOperator(e.target.value)}
                        >
                            <option>=</option>
                            <option>!=</option>
                            <option>&lt;</option>
                            <option>&gt;</option>
                            <option>&lt;=</option>
                            <option>&gt;=</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Value</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder='Enter value (e.g., "US")' 
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                        <Button variant="primary" onClick={addFilter}>
                            Add Filter
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Form>
                <ul style={{ marginTop: '20px', paddingLeft: '0' }}>
                    {filters.map((filter, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            <span style={{ 
                                marginRight: '10px',
                                backgroundColor: '#4D5656', 
                                padding: '5px 10px', 
                                borderRadius: '5px'
                             }}>
                                {filter.parameter} {filter.operator} "{filter.value}"
                            </span>
                            <Button variant="secondary" onClick={() => removeFilter(index)}>Remove</Button>
                        </li>
                    ))}
                </ul>
            </Modal.Body>
        </Modal>
    );
};

export default SearchModal;