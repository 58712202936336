import React, {useState, useEffect} from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Modal, Container, Form, Row, Col, Button} from "react-bootstrap";
import { TextField } from "@mui/material";
import { useUser} from "../contexts/user";
import Autocomplete from '@mui/material/Autocomplete';
import '../css/poi.css';
import { MsgTypes } from "../utils/Websockets";
import { EDITABLE_SCHEMAS } from "../utils/constants";

const ModalAddSchema = ({
    show,
    setShow,
    sat,
    onAddSchema
}) => {
    const { ws, username, newMsg } = useUser();
    const [selectedScheme, setSelectedScheme] = useState(EDITABLE_SCHEMAS[0]);
    const [saving, setSaving] = useState(false);
    const handleChange = (_, newValue) => {
        setSelectedScheme(newValue);
    };

    useEffect(()=>{
        if(!newMsg)return;
        if (newMsg.id === MsgTypes.SaveScheme){
            setSaving(false);
            if(newMsg.data.addedRows === 1){
                setShow(false);
            }
        }
    },[newMsg, setShow]);

    const darkTheme = createTheme({
        palette: {
        mode: 'dark',
        },
    });

    const handleClose = () => {
        setShow(false);
    };


    const handleSubmit = (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const source = data.get("source");
        const value = data.get("value");
        if(!selectedScheme || !source || !value){
            return;
        }
        setSaving(true);
        ws.saveScheme(sat?.SatNo, username, selectedScheme, source, value);

        const newSchema = {
            Id: Date.now(),
            Scheme: selectedScheme,
            Source: source,
            Value: value,
            VerifiedBy: username,
            UpdatedAt: new Date().toISOString()
        };
        onAddSchema(newSchema);
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Modal
                className="addSchemaModal"
                show={show}
                onHide={handleClose}
                style={{ background: "#000000c7" }}
            >
                <Modal.Header closeButton >
                    <Modal.Title>
                        Add Schema
                    </Modal.Title>
                </Modal.Header>
                
                <Modal.Body style={{padding:0, overflow:"hidden"}}>
                    <Container>
                        <Row className="pt-4">
                            <Col>
                                <Form id="poiForm" onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="poi">
                                        
                                        <Autocomplete
                                            className="mb-2"
                                            disablePortal
                                            id="schemaCombo"
                                            options={EDITABLE_SCHEMAS}
                                            value={selectedScheme}
                                            onChange={handleChange}
                                            required
                                            renderInput={(params) => <TextField name="scheme" {...params} label="Schema" />}
                                        />

                                        <TextField
                                            className="mb-2"
                                            style={{width:"100%"}}
                                            label="Source URL"
                                            name="source"
                                            type="url"
                                            placeholder="URL of where you found this information"
                                            required
                                        />
                                        
                                        <TextField 
                                            className="mb-2"
                                            minRows={3}
                                            style={{width:"100%"}}
                                            name="value"
                                            label="Value" 
                                            placeholder="POI details, e.g Spacecraft maneuvered with +i2m/s dV."
                                            required
                                        />

                                        <div className="pb-2">
                                            Verified by: <b>{username}</b> 
                                        </div>
                                        <br/>
                                        <Button
                                            type="submit"
                                            disabled={saving}
                                            variant="warning"
                                            style={{
                                                width:"150px"
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Group>

                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </ThemeProvider>
    );
};
export default ModalAddSchema;
