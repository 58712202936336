import {v4 as uuidv4} from "uuid";
import Cookies from "js-cookie";

const url = window.location.host.replace("probe","nps").replace(":3000",":9001");
const uri = `${ (url.includes("localhost") ? "ws://" : "wss://")}${url}`;
export const MsgTypes = {
    Login : "1",
    SavePOI : "2",
    SaveScheme: "3",
    UpdatePsra: "4",
    Select: "5",
    SelectUpdate: "6",
    SaveSatellite: "7",
    CreateSatellite: "8",
    DeleteSatellite: "9"
};

export const Connect = (setNewMsg, username, password, accesstoken)=>{
    let ws = new WebSocket(uri);

    ws.onopen = function() {
        console.log("WebSocket connected");
        const queryParams = new URLSearchParams(window.location.search);
        const urlToken = queryParams.get('accesstoken'); // Retrieve the 'accesstoken' parameter
        const accessToken = urlToken 
            ? urlToken
            : Cookies.get("accessToken");
            
        ws.login(username,password,accessToken);
    };
    
    ws.onmessage = function(event) {
        var msg = JSON.parse(event.data);
        if (msg && msg.id === MsgTypes.Login && Cookies.get("accessToken") && !msg.data.UDLLogin) {
            // If Access Token Expired
            Cookies.remove("accessToken");
            window.location.reload();
        }
        setNewMsg(msg)
    };
    
    ws.onclose = function(event) {
        console.log("Connection closed", event.code, event.reason);
    };
    
    ws.onerror = function(event) {
        console.log("WebSocket error observed:", event);
    };
    
    ws.logout = ()=>{
        ws.close();
    };

    ws.login = function(username, password, accesstoken) {
        if(!ws.OPEN){
            ws = new WebSocket(uri);
        }
        let payload = {
            id: MsgTypes.Login,
            action: "Login",
            args: {
                clientUuid: uuidv4()
            }
        };
    
        if(accesstoken){
            payload.args.AccessToken = accesstoken;
        }else if(username && password){
            payload.args.udl = {
                username: username,
                password: password
            };
        }
        ws.send(JSON.stringify(payload));
    };

    ws.updatePsra = (id, source, value, username)=>{
        if (!ws.OPEN) {
            ws = new WebSocket(uri);
        }
        let payload = {
            id: `${MsgTypes.UpdatePsra}-${id}`,
            action: "Update",
            apiKey: "INSECURE",
            args: {
                table: "PSRA",
                statements: [
                    {
                        where: [
                            {
                                column: "Id",
                                operator: "=",
                                value: id
                            }
                        ],
                        updates: {
                            "Source" : source,
                            "Value" : value,
                            "VerifiedBy" : username,
                            "Verified": "1",
                        }
                    }
                ],
            }
        };
        if(accesstoken){
            payload.args.AccessToken = accesstoken;
        }
        ws.send(JSON.stringify(payload));
    };

    ws.savePOI = (satNo, username, poi, timestamp, source)=>{
        if(!ws.OPEN){
            ws = new WebSocket(uri);
        }
        let payload = {
            id: MsgTypes.SavePOI,
            action: "Insert",
            args: {
                table: "PSRA",
                statements: [
                    {
                        "SatNo": satNo,
                        "Scheme": "poi",
                        "Value": poi,
                        "Verified": "1",
                        "VerifiedBy": username,
                        "Source": source,
                        "Timestamp": timestamp
                    }
                ]
            }
        };
    
        if(accesstoken){
            payload.args.AccessToken = accesstoken;
        }
        ws.send(JSON.stringify(payload));
    };
    
    ws.saveScheme = (satNo, username, scheme, source, value)=>{
        if(!ws.OPEN){
            ws = new WebSocket(uri);
        }
        let payload = {
            id: MsgTypes.SaveScheme,
            action: "Insert",
            args: {
                table: "PSRA",
                statements: [
                    {
                        "SatNo": satNo,
                        "Value": value,
                        "Verified": "1",
                        "VerifiedBy": username,
                        "Source": source,
                        "Scheme" : scheme,
                    }
                ]
            }
        };
    
        if(accesstoken){
            payload.args.AccessToken = accesstoken;
        }
        ws.send(JSON.stringify(payload));
    };

    ws.getAllPsraData = function() {
        // NPS PSRA special select
        ws.send(JSON.stringify({
            id: MsgTypes.Select,
            action: "Select",
            apiKey: "INSECURE",
            args: {
                table: "PSRA",
                columns: ["id"],
                where: [
                    {column: "specialselect", operator: "=", value: "getallpsradata"},
                    {column: "id", operator: "=", value: "all"}
                ]
            }
        }));

        ws.getPsraData = function(rowId, getUpdate = false) {
            // NPS PSRA special select
            ws.send(JSON.stringify({
                id: getUpdate ? MsgTypes.SelectUpdate : MsgTypes.Select,
                action: "Select",
                apiKey: "INSECURE",
                args: {
                    table: "PSRA",
                    columns: ["id"],
                    where: [
                        {column: "specialselect", operator: "=", value: "getallpsradata"},
                        {column: "id", operator: "=", value: rowId}
                    ]
                }
            }));
        }
    };

    ws.SaveSatellite = ({ satNo, username, scheme, source, value }) => {
        if (!ws.OPEN) {
            ws = new WebSocket(uri);
        }
        let payload = {
            id: MsgTypes.SaveScheme,
            action: "Insert",
            args: {
                table: "PSRA",
                statements: [
                    {
                        "SatNo": satNo,
                        "Value": value,
                        "Verified": "1",
                        "VerifiedBy": username,
                        "Source": source,
                        "Scheme": scheme,
                    }
                ]
            }
        };
    
        if (accesstoken) {
            payload.args.AccessToken = accesstoken;
        }
        ws.send(JSON.stringify(payload));
    };

    ws.CreateSatellite = ({ satNo, username, scheme, source, value }) => {
        if (!ws.OPEN) {
            ws = new WebSocket(uri);
        }
        let payload = {
            id: MsgTypes.CreateSatellite,
            action: "Insert",
            args: {
                table: "PSRA",
                statements: [
                    {
                        "SatNo": satNo,
                        "Value": value,
                        "Verified": "1",
                        "VerifiedBy": username,
                        "Source": source,
                        "Scheme": scheme,
                    }
                ]
            }
        };
    
        if (accesstoken) {
            payload.args.AccessToken = accesstoken;
        }
        ws.send(JSON.stringify(payload));
    };

    ws.DeleteSatellite = ({ Id }) => {
        if (!ws.OPEN) {
            ws = new WebSocket(uri);
        }

        let payload = {
            id: MsgTypes.DeleteSatellite,
            action: "delete",
            args: {
                table: "PSRA",
                statements: [
                    {
                        where: [
                            {
                                column: "Id",
                                operator: "=",
                                value: Id
                            }
                        ]
                    }
                ]
            }
        };

        if (accesstoken) {
            payload.args.AccessToken = accesstoken;
        }
        ws.send(JSON.stringify(payload));
    };

    return ws;
}
