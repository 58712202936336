import React, { useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './pages/About';
import Dossier from './pages/Dossier';
import Home from './pages/Home';
import { Connect } from "./utils/Websockets";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser} from "./contexts/user";

const Main = () => {
    const { setWs, setNewMsg } = useUser();
    useEffect(()=>{
        setWs(Connect(setNewMsg));
    },[setNewMsg, setWs]);

    return (
        <>
            <ToastContainer></ToastContainer>
            <Header />
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/dossier/:id" element={<Dossier />} />
                    {/* Add your other routes here */}
                </Routes>
            </Router>
            <Footer />
        </>
    );
};

export default Main;
