import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Modal} from "react-bootstrap";
import '../css/login.css';
import {toast} from 'react-toastify';
import { useUser} from "../contexts/user";
import { MsgTypes } from "../utils/Websockets";
import Cookies from 'js-cookie';

const Login = ({
    show,
    setShow,
}) => {
    const { ws, newMsg, setUsername} = useUser();
    const [user,setUser] = useState("Guest");
    const [disabled, isDisabled] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        isDisabled(true);
        const data = new FormData(e.target);
        setUser(data.get("username"));
        ws.login(data.get("username"), data.get("password"));
    };

    useEffect(()=>{
        // Handle login response
        if (newMsg && newMsg.id === MsgTypes.Login) {
            isDisabled(false);
            // User logged in with UDL creds
            if(newMsg.data.NPSLogin && newMsg.data.UDLLogin){
                ws.getAllPsraData();
                Cookies.set('accessToken', newMsg.data.AccessToken, { secure: true, expires: 1, sameSite: 'Strict' });
                setUsername(newMsg.data.Username ?? user);
                setShow(false);
            }
            //User logged in as guest
            else if (newMsg.data.NPSLogin){
                Cookies.remove("accessToken");
                ws.getAllPsraData();
                setUsername(user);
            }
            else{
                toast.error("Invalid username or password");
            }
        }
    },[newMsg, setShow, setUsername, user, ws]);

    return (
        <>
            <Modal
                className="login-modal"
                show={show}
                onHide={()=>setShow(false)}
            >
                <Modal.Body>
                    <p>Login using your UDL Credentials</p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Control
                                style={{marginBottom:"20px"}}
                                type="text" 
                                placeholder="Username"
                                name="username"
                                required
                            />
                            <Form.Control 
                                style={{marginBottom:"20px"}}
                                autoComplete="new-password"
                                type="password"
                                name="password"
                                placeholder="Password"
                                required
                            />
                            <Button
                                disabled={disabled}
                                variant="light" 
                                type="submit"
                            >
                                Login
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );    
};

export default Login;
