import React, {useState, useEffect, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import { Card, Col, Row, Button, Image, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { FaRegEdit } from "react-icons/fa";
import "../css/cards.css";
import OverlayText from './OverlayText';
import ModalIframe from '../components/ModalIframe';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {getFlagLink, timeDifference, isGeo, isLoggedIn, getScheme } from '../utils/Utils';
import { FaFolder } from "react-icons/fa";
import { useUser } from "../contexts/user";
import { GiChart } from 'react-icons/gi';
import SpacefanaDashboard from './SpacefanaDashboard';

const useSingleAndDoubleClick = (actionSingleClick, actionDoubleClick) => {
    const [timer, setTimer] = useState(null);
    const delay = 200; // milliseconds

    const handleClick = useCallback(() => {
        if (!timer) {
            setTimer(
                setTimeout(() => {
                    actionSingleClick();
                    setTimer(null);
                }, delay)
            );
        } else {
            clearTimeout(timer);
            setTimer(null);
            actionDoubleClick();
        }
    }, [actionSingleClick, actionDoubleClick, timer, delay]);

    return handleClick;
}


const SatelliteCard = ({
    setShowSatModal,
    loggedIn,
    sat,
    setSelectedSat,
}) => {

    const navigate = useNavigate();
    const { username } = useUser();
    const [showIframe, setShowIframe] = useState(false);
    const [iframeUrl] = useState("");
    const [showImage, setShowImage] = useState(true);
    const [maxCols, setMaxCols] = useState(3);
    const [showSpacefanaModal, setShowSpacefanaModal] = useState(false);

    const clickModalBtn = (sat)=>{
        setShowSatModal(true);
        setSelectedSat(sat);
    };

    const scBtnClick = ()=>{
        toggleSpacefanaModal();
    };

    const openInNewTab = () => {
        return window.open(`/dossier/${sat?.SatNo}`, "_blank", "noopener,noreferrer");
    };

    const handleClick = useSingleAndDoubleClick(
        () => navigate(`/dossier/${sat?.SatNo}`),
        openInNewTab,
    );

    const hideImage = ()=>{
        setShowImage(false);
    }

    const toggleSpacefanaModal = () => {
        setShowSpacefanaModal(!showSpacefanaModal);
    };

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip">
          {text}
        </Tooltip>
      );

    const getName = (sat)=>{
        const scheme = getScheme(sat.Data, ["name"]);
        if (scheme?.[0].Value === "N/A") return "";
        return "Alias: "+ scheme.map(s=>s.Value).join(",");
    }

    useEffect(()=>{
        setMaxCols(isLoggedIn(username) && sat?.HrrRank ? 3 : 4);
    },[username, sat?.HrrRank]);

    return (
        <>
            <ModalIframe
                show={showIframe}
                setShow={setShowIframe}
                url={iframeUrl}
            />

            <Card
                style={{
                    backgroundColor: "#181b1f",
                    color: "white",
                    height: (setShowSatModal ? "100%" : ""),
                    borderRadius: "5px",
                }}
            >
                
                <Carousel
                    showThumbs={false}
                >
                    {(sat?.getImages(sat?.Data) &&
                        sat?.getImages(sat?.Data).map((url) => (
                            <Image
                                key={url}
                                style={{
                                    width:"100%",
                                    height:"100%"
                                }}
                                src={url}
                                alt={sat?.Name}
                            />
                        ))
                    )}
                </Carousel>
                
                {showImage && (
                    <Image
                        style={{
                            width:"50px",
                            height: "50px",
                            position: 'absolute',
                            zindex:9999,
                            top: 0,
                            left: 5 
                        }}
                        alt={sat?.CountryId}
                        src={getFlagLink(sat)}
                        onError={hideImage}
                    />
                )}
                
                <Card.Body>
                
                    <Card.Title className="card-text bold">
                        ({sat?.SatNo}) {sat?.Name}
                    </Card.Title>
                    <div style={{fontSize: "12px", marginBottom: "10px"}}>
                        {getName(sat)}
                    </div>
                    <div className="card-text">
                       
                        <Row className="bold">
                            <Col xs={4}>Last Seen</Col>
                            {isGeo(sat) && (
                                <>
                                    <Col xs={4}>Lon (°E)</Col>
                                    <Col xs={4}>Drift (°E/d)</Col>
                                </>
                            )}
                            {!isGeo(sat) && (
                                 <>
                                 <Col xs={4}>Inc (°)</Col>
                                 <Col xs={4}>Raan (°)</Col>
                             </>
                            )}
                        </Row>
                        <Row>
                            <Col xs={4}>{timeDifference(sat?.Epoch)}</Col>
                            {isGeo(sat) && (
                                <>
                                    <Col xs={4}>{sat?.Longitude}</Col>
                                    <Col xs={4}>{sat?.LonDriftDegreesPerDay}</Col>
                                </>
                            )}
                            {!isGeo(sat) && (
                                 <>
                                 <Col xs={4}>{sat?.Inclination}</Col>
                                 <Col xs={4}>{sat?.Raan}</Col>
                             </>
                            )}
                        </Row> 

                        <br/>
                        <Row className="bold">
                            {isLoggedIn(username) && sat?.HrrRank && (
                                <Col xs={2}>Rank</Col>
                            )}
                            <Col xs={maxCols}>Regime</Col>
                            <Col xs={maxCols}>Type</Col>
                            <Col xs={maxCols}>Status</Col>
                        </Row>
                        <Row>
                            {isLoggedIn(username) && sat?.HrrRank && (
                                <Col xs={2}>{sat?.HrrRank}</Col>
                            )}
                            <Col xs={maxCols}>
                                <OverlayText text={sat?.Regime.toLowerCase()} maxLength={8} />
                            </Col>
                            <Col xs={maxCols}>
                            <OverlayText text={sat?.ObjectType ? sat?.ObjectType.toLowerCase() : ""} maxLength={8} />
                            </Col>
                            <Col xs={isLoggedIn(username) && sat?.HrrRank ? 3 : 4}>
                                <OverlayText text={sat?.getStatus(sat?.Data)} maxLength={8}/>
                            </Col>
                        </Row> 

                    </div>
                    <br/>
                    <OverlayText
                        text={sat?.getDescription(sat?.Data)}
                        maxLength={125}
                    />

                </Card.Body>

                {setShowSatModal ? ( <Card.Footer>
                    <Row className="text-center">
                        <Col xs={4}>
                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip("View Dossier (double-click to open in new tab)")}
                            >
                                <Button 
                                    variant="dark"
                                    style={{color:"rgb(235 215 147)"}}
                                    onClick={handleClick}
                                    onDoubleClick={handleClick}
                                    onContextMenu={openInNewTab}
                                >
                                    <FaFolder/>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={4}>
                            {loggedIn && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip("Edit Data")}
                                >
                                    <Button variant="dark" onClick={()=>{clickModalBtn(sat)}}>
                                        <FaRegEdit/>
                                    </Button>
                                </OverlayTrigger>
                            )}
                        </Col>
                        <Col xs={4}>
                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip("View Elset History")}
                            >
                                <Button 
                                    variant="dark"
                                    onClick={scBtnClick}
                                >
                                    <GiChart />
                                </Button>
                            </OverlayTrigger>
                            <Modal show={showSpacefanaModal} onHide={toggleSpacefanaModal} size="lg" centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Spacefana Dashboard</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <SpacefanaDashboard satNumber={sat?.SatNo} />
                                </Modal.Body>
                            </Modal>
                        </Col>
                    </Row>
                </Card.Footer>):(<></>)}
            
            </Card>
        </>
    );
};

export default SatelliteCard;
